import React, { useState, useRef } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';

const Paginate = ({ params, setParams, isInline, total }) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdown = useRef(null);

  useOutsideClick(dropdown, () => {
    if (isVisible) toggle();
  });

  const toggle = () => setIsVisible(!isVisible);

  const handleClick = value => {
    if (isInline) setParams(Number(value));
    else setParams({ ...params, paginate: value, page: 1 });
    toggle();
  };

  const showNumber = () => {
    if (isInline) return params;
    else return params?.paginate ? params.paginate : 20;
  };

  return (
    <div className="paginate">
      <div className="root" onClick={toggle}>
        <p>{showNumber()}</p>
        <FaAngleDown className={activeClass('rotate_arrow', isVisible)} />
      </div>

      {isVisible && (
        <div className="dropdown_content" ref={dropdown}>
          <div onClick={() => handleClick(20)}>20</div>
          <div onClick={() => handleClick(50)}>50</div>
          <div onClick={() => handleClick(100)}>100</div>
        </div>
      )}
    </div>
  );
};

export default Paginate;
