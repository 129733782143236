const ORDERS = '[CMS_ORDERS]';

const types = {
  GET_ORDERS: `${ORDERS} GET_ORDERS`,
  SET_ORDERS: `${ORDERS} SET_ORDERS`,

  SET_PARAMS: `${ORDERS} SET_PARAMS`,
  REMOVE_PARAMS: `${ORDERS} REMOVE_PARAMS`,

  GET_SINGLE_ORDER: `${ORDERS} GET_SINGLE_ORDER`,
  SET_SINGLE_ORDER: `${ORDERS} SET_SINGLE_ORDER`,
  CLEAR_SINGLE_ORDER: `${ORDERS} CLEAR_SINGLE_ORDER`,
  GET_SINGLE_ORDER_ERROR: `${ORDERS} GET_SINGLE_ORDER_ERROR`,

  UPDATE_DELIVERY_STATUS: `${ORDERS} UPDATE_DELIVERY_STATUS`,
  UPDATE_DELIVERY_STATUS_SUCCESS: `${ORDERS} UPDATE_DELIVERY_STATUS_SUCCESS`,

  SEND_CUSTOMER_NOTES: `${ORDERS} SEND_CUSTOMER_NOTES`,

  GET_INTERNAL_NOTES: `${ORDERS} GET_INTERNAL_NOTES`,
  SET_INTERNAL_NOTES: `${ORDERS} SET_INTERNAL_NOTES`,

  CREATE_INTERNAL_NOTE_REQUEST: `${ORDERS} CREATE_INTERNAL_NOTE_REQUEST`,
  CREATE_INTERNAL_NOTE_SUCCESS: `${ORDERS} CREATE_INTERNAL_NOTE_SUCCESS`,

  UPDATE_INTERNAL_NOTE_REQUEST: `${ORDERS} UPDATE_INTERNAL_NOTE_REQUEST`,
  UPDATE_INTERNAL_NOTE_SUCCESS: `${ORDERS} UPDATE_INTERNAL_NOTE_SUCCESS`,

  DELETE_INTERNAL_NOTE_REQUEST: `${ORDERS} DELETE_INTERNAL_NOTE_REQUEST`,
  DELETE_INTERNAL_NOTE_SUCCESS: `${ORDERS} DELETE_INTERNAL_NOTE_SUCCESS`,

  GET_STATUS_LIST: `${ORDERS} GET_STATUS_LIST`,
  SET_STATUS_LIST: `${ORDERS} SET_STATUS_LIST`
};

export default types;
