import { useCallback, useEffect } from 'react';

const useUserKeyPress = (code, callback) => {
  const handleUserKeyPress = useCallback(e => {
    const { keyCode } = e;

    if (keyCode === code) {
      callback(e);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
};

export default useUserKeyPress;
