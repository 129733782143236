import React, { useState, useRef } from 'react';
import SimpleReactCalendar from 'simple-react-calendar';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import useOutsideClick from 'helpers/useOutsideClick';
import { momentFormatDate } from 'helpers/momentFormatDate';

const Calendar = ({ name, label, value, setValue, errors, touched }) => {
  const [isVisible, setIsVisible] = useState(false);
  const calendar = useRef(null);

  useOutsideClick(calendar, () => {
    if (isVisible) setIsVisible(false);
  });

  const handleClick = date => {
    setValue(date);
    setIsVisible(false);
  };

  return (
    <div className="input_holder">
      <div className="form_field">
        {isVisible && (
          <div className="simple_react_calendar" ref={calendar}>
            <SimpleReactCalendar
              onSelect={date => handleClick(date)}
              selected={value}
              activeMonth={new Date()}
              weekStartsOn={1}
              headerPrevArrow={<ArrowLeft />}
              headerNextArrow={<ArrowRight />}
            />
          </div>
        )}
        <input
          readOnly
          onClick={() => setIsVisible(true)}
          value={value ? momentFormatDate(value) : momentFormatDate(new Date())}
          style={value ? { color: '#333e48' } : { color: 'rgba(0,0,0,0.4)' }}
        />
        <label>{label}</label>
      </div>
      {errors && errors[name] && touched[name] && (
        <p className="error_msg">{errors[name]}</p>
      )}
    </div>
  );
};

export default Calendar;
