export const toggle = (id, opened, setOpened) => {
  if (opened.includes(id)) {
    setOpened(opened.filter(item => item !== id));
  } else {
    if (!opened.includes(id)) setOpened([...opened, id]);
  }
};

export const toggleStay = (id, opened, setOpened) => {
  if (!opened.includes(id)) setOpened([...opened, id]);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const onDragEnd = (name, result, items, setItems, changeOrder, orders='category_orders') => {
  if (result.type === name && result.destination) {
    let list = reorder(items, result.source.index, result.destination.index);
    let sorted = [];
    list.map((item, index) => sorted.push({ ...item, order: index + 1 }));
    setItems(sorted);

    let formated = sorted.map(item => ({ id: item.id, order: item.order }));
    changeOrder({ [orders]: formated });
  }
};

export const handleDrag = (result, name, items, setItems, callback) => {
  if (result.type === name && result.destination) {
    let list = reorder(items, result.source.index, result.destination.index);
    let sorted = [];
    setItems(sorted);
    list.map((item, i) => sorted.push({ ...item, order: i + 1 }));
    callback(sorted);
  }
};
