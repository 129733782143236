const KEY = '[CMS_NEWS]';

const types = {
  GET_NEWS: `${KEY} GET_NEWS`,
  SET_NEWS: `${KEY} SET_NEWS`,

  GET_SINGLE_NEWS: `${KEY} GET_SINGLE_NEWS`,
  SET_SINGLE_NEWS: `${KEY} SET_SINGLE_NEWS`,
  GET_SINGLE_NEWS_ERROR: `${KEY} GET_SINGLE_NEWS_ERROR`,

  CREATE_NEWS: `${KEY} CREATE_NEWS`,
  UPDATE_NEWS: `${KEY} UPDATE_NEWS`,
  DELETE_NEWS: `${KEY} DELETE_NEWS`,
  UPDATE_IMAGE: `${KEY} UPDATE_IMAGE`,

  SET_PARAMS: `${KEY} SET_PARAMS`,
  DELETE_PARAMS: `${KEY} DELETE_PARAMS`
};

export default types;
