import produce from 'immer';
import types from './constants';

export const initialState = {
  products: [],
  params: {}
};

/* eslint-disable default-case */
const visibleOnSiteReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_PRODUCTS:
        draft.products = action.payload;
        break;
      case types.SET_PARAMS: {
        const {
          manufacturers,
          brands,
          suppliers,
          categories,
          ...rest
        } = action.payload;
        let params = {};
        if (action.payload.hasOwnProperty('manufacturers')) {
          if (action.payload.manufacturers) {
            let manufacturers = [];
            if (typeof action.payload.manufacturers === 'string') {
              manufacturers = [...action.payload.manufacturers.split(',')];
            } else {
              action.payload.manufacturers.forEach(element => {
                manufacturers.push(element.id);
              });
            }
            params = {
              ...params,
              manufacturers: manufacturers.toString()
            };
          } else {
            params = {
              ...params,
              manufacturers: undefined
            };
          }
        }
        if (action.payload.hasOwnProperty('suppliers')) {
          if (action.payload.suppliers) {
            let suppliers = [];
            if (typeof action.payload.suppliers === 'string') {
              suppliers = [...action.payload.suppliers.split(',')];
            } else {
              action.payload.suppliers.forEach(element => {
                suppliers.push(element.id);
              });
            }
            params = {
              ...params,
              suppliers: suppliers.toString()
            };
          } else {
            params = {
              ...params,
              suppliers: undefined
            };
          }
        }
        if (action.payload.hasOwnProperty('categories')) {
          if (action.payload.categories) {
            let categories = [];
            if (typeof action.payload.categories === 'string') {
              categories = [...action.payload.categories.split(',')];
            } else {
              action.payload.categories.forEach(element => {
                categories.push(element.id);
              });
            }
            params = {
              ...params,
              categories: categories.toString()
            };
          } else {
            params = {
              ...params,
              categories: undefined
            };
          }
        }
        if (action.payload.hasOwnProperty('brands')) {
          if (action.payload.brands) {
            let brands = [];
            if (typeof action.payload.brands === 'string') {
              brands = [...action.payload.brands.split(',')];
            } else {
              action.payload.brands.forEach(element => {
                brands.push(element.id);
              });
            }
            params = {
              ...params,
              brands: brands.toString()
            };
          } else {
            params = {
              ...params,
              brands: undefined
            };
          }
        }
        draft.params = { ...params, ...rest };
        break;
      }
      case types.DELETE_PARAMS:
        draft.params = {};
        break;
    }
  });

export default visibleOnSiteReducer;
