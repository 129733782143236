import React, { useEffect } from 'react';

import TopPart from './TopPart';
import Content from './Content';
import { activeClass } from 'helpers/activeClass';

const SideBar = ({ active, setActive }) => {
  const content = document.querySelector('.cms_content');
  const navbar = document.querySelector('.cms_nav_bar_wrapper');
  const toggle = () => setActive(active ? false : true);
  const closeSidebar = () => window.innerWidth <= 1200 && setActive(false);

  useEffect(() => {
    if (content && navbar) {
      if (active) {
        content.classList.add('active');
        navbar.classList.add('active');
      } else {
        content.classList.remove('active');
        navbar.classList.remove('active');
      }
    }
  }, [content, navbar, active]);

  return (
    <div className={activeClass('side_bar_mask', active)} onClick={toggle}>
      <aside
        className={activeClass('side_bar_wrapper', active)}
        onClick={e => e.stopPropagation()}
      >
        <TopPart toggle={toggle} />
        <Content toggle={closeSidebar} />
      </aside>
    </div>
  );
};

export default SideBar;
