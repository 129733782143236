import { defineMessages } from 'react-intl';

export const scope = 'cms_table_helpers';

export default defineMessages({
  search_placeholder: {
    id: `${scope}.search_placeholder`,
    defaultMessage: 'Pretraži'
  },
  table_no_result: {
    id: `${scope}.table_no_result`,
    defaultMessage: 'Nema rezultata.'
  },
  deleted_modal_template_text: {
    id: `${scope}.deleted_modal_template_text`,
    defaultMessage: 'Da li ste sigurni da želite da '
  },
  deleted_modal_delete_text: {
    id: `${scope}.deleted_modal_delete_text`,
    defaultMessage: 'obrišite '
  },
  deleted_modal_unlink_text: {
    id: `${scope}.deleted_modal_unlink_text`,
    defaultMessage: 'razdvojite kategoriju '
  },
  deleted_modal_yes: {
    id: `${scope}.deleted_modal_yes`,
    defaultMessage: 'Da'
  },
  deleted_modal_no: {
    id: `${scope}.deleted_modal_no`,
    defaultMessage: 'Ne'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Ime'
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Dobavljač'
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Aktivan'
  },
  product_is_visible_on_site: {
    id: `${scope}.product_is_visible_on_site`,
    defaultMessage: 'Proizvod je vidljiv na sajtu'
  },
  product_is_not_visible_on_site: {
    id: `${scope}.product_is_not_visible_on_site`,
    defaultMessage: 'Proizvod nije vidljiv na sajtu'
  },
  product_is_active: {
    id: `${scope}.product_is_active`,
    defaultMessage: 'Proizvod je aktivan'
  },
  product_is_not_active: {
    id: `${scope}.product_is_not_active`,
    defaultMessage: 'Proizvod nije aktivan'
  },
  product_is_not_connected: {
    id: `${scope}.product_is_not_connected`,
    defaultMessage: 'Proizvod nije povezan'
  },
  separate_product: {
    id: `${scope}.separate_product`,
    defaultMessage: 'Are you sure you want to separate the product?'
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Pretraži'
  }
});
