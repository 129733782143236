import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';
import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'cms/components/Selects/redux/reducer';
import saga from 'cms/components/Selects/redux/saga';
import { fetchBrands } from 'cms/components/Selects/redux/actions';
import { makeSelectBrands } from 'cms/components/Selects/redux/selectors';
import messages from '../messages';
import useUserKeyDown from 'helpers/useUserKeyDown';

const key = 'selects';

const BrandsSelect = ({ params, setParams }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [delay, setDelay] = useState('');
  const results = useSelector(makeSelectBrands());
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isVisible && params && results.length) {
      let activeItem = results.map(item => item.name).indexOf(params.brand);
      if (activeItem > -1) setActiveIndex(activeItem);
      else setActiveIndex(0);
    }
  }, [isVisible, results, params]);

  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useUserKeyDown('ArrowUp', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex > 0) setActiveIndex(prev => prev - 1);
      else setActiveIndex(results.length - 1);
    }
  });

  useUserKeyDown('ArrowDown', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex < results.length - 1) setActiveIndex(prev => prev + 1);
      else setActiveIndex(0);
    }
  });

  useUserKeyDown('Enter', [activeIndex, isVisible, results], () => {
    if (isVisible && results.length) handleClick(results[activeIndex]);
  });

  useEffect(() => {
    if (isVisible) dispatch(fetchBrands('', 1000));
  }, [isVisible, dispatch]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      dispatch(fetchBrands(text));
    }, 500);
    setDelay(copyState);
    setActiveIndex(0);
  };

  // const handleClick = item => {
  //   setParams({ ...params, brand: item.name });
  //   toggle();
  // };

  const handleClick = item => {
    // const prev = params.brands ? [...params.brands] : [];
    let prev = null;
    if (typeof params.brands === 'string') {
      let data = [];
      const brands = params.brands.split(',');
      brands.forEach(man => {
        const item = results.find(res => String(res.id) === man);
        if (item) data.push(item);
      });
      prev = [...data];
    } else {
      prev = params.brands ? [...params.brands] : [];
    }
    if (!prev.find(p => p.name === item.name)) {
      setParams({
        ...params,
        brands: [...prev, { name: item.name, id: item.id }]
      });
      // toggle();
    }
  };

  // const handleDelete = () => {
  //   const { brand: tmp, ...rest } = params;
  //   setParams(rest);
  // };

  const handleDelete = brand => () => {
    const rest = params.brand.filter(m => m.name !== brand.value);
    setParams({ ...params, brand: rest });
  };
  const getBrands = () => {
    const mans = params.brands.split(',');
    let data = [];
    mans.forEach(m => {
      const el = results.find(res => String(res.id) === m);
      if (el) data.push(el);
    });
    return data;
  };
  return (
    <div className="group">
      <h3>{formatMessage(messages.brand)}</h3>
      <div className={activeClass('search_select_wrapper', isVisible)}>
        <div className="search_select_holder">
          <div className="root" onClick={toggle}>
            {/* <p>
              {params.brand ? (
                <span>
                  {params.brand}
                  <FaTimes onClick={handleDelete} />
                </span>
              ) : (
                formatMessage(messages.choose_brand)
              )}
            </p> */}
            <p>
              {params.brands && params.brands.length
                ? typeof params.brands !== 'string'
                  ? params.brands.map((brand, i) => {
                      return (
                        <span key={i}>
                          {brand.name}
                          <FaTimes onClick={handleDelete(brand)} />
                        </span>
                      );
                    })
                  : getBrands().map((brand, i) => {
                      return (
                        <span key={i}>
                          {brand.name}
                          <FaTimes onClick={handleDelete(brand)} />
                        </span>
                      );
                    })
                : formatMessage(messages.choose_brand)}
            </p>
            <span className="icon">
              <FaSortDown />
            </span>
          </div>
          <label>{formatMessage(messages.brands)}</label>
          {isVisible && (
            <div className="dropdown_holder" ref={modal}>
              <div className="dropdown_content">
                <input
                  type="text"
                  placeholder={formatMessage(messages.search_placeholder)}
                  onChange={handleChange}
                  autoFocus
                />
                {results.length ? (
                  <ul>
                    {results.map((item, index) => (
                      <li
                        key={item.id}
                        onClick={() => handleClick(item)}
                        onMouseEnter={() => setActiveIndex(index)}
                        className={activeClass('', activeIndex === index)}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="no_result">
                    {formatMessage(messages.no_result)}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandsSelect;
