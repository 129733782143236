import Axios from 'axios';
import { baseApiUrl } from 'env';

const axiosConfig = {
  headers: {
    Authorization: `Bearer ${localStorage.token?.split('"').join('')}`
  }
};

const API = {
  connectedSuppliers(supplier) {
    return Axios.get(
      `${baseApiUrl}admin/suppliers-connected/${supplier}`,
      axiosConfig
    );
  },
  unconnectedSuppliers(supplier) {
    return Axios.get(
      `${baseApiUrl}admin/suppliers-unconnected/${supplier}`,
      axiosConfig
    );
  }
};
export default API;
