import React from 'react';
import { useIntl } from 'react-intl';
import { FaCheck } from 'react-icons/fa';
import { activeClass } from 'helpers/activeClass';
import messages from '../messages';

const Live = ({ params, setParams }) => {
  const { formatMessage } = useIntl();

  const toggle = name => {
    if (params[name]) {
      const { [name]: tmp, ...rest } = params;
      setParams(rest);
    } else {
      setParams({ ...params, [name]: 1 });
    }
  };

  return (
    <div className="group">
      <h3>{formatMessage(messages.visible_on_site)}</h3>
      <div className="form_checkbox" onClick={() => toggle('live')}>
        <div className={activeClass('box', params.live === 1)}>
          <span>
            <FaCheck />
          </span>
        </div>
        <label>{formatMessage(messages.shown)}</label>
      </div>
      <div className="form_checkbox" onClick={() => toggle('dead')}>
        <div className={activeClass('box', params.dead === 1)}>
          <span>
            <FaCheck />
          </span>
        </div>
        <label>{formatMessage(messages.not_shown)}</label>
      </div>
    </div>
  );
};

export default Live;
