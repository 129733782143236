import React from 'react';

const EnFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="20"
    viewBox="0 0 25 15"
  >
    <path fill="#00247d" d="M0 0H25V15H0z"></path>
    <path stroke="#fff" strokeWidth="3" d="M0 0l25 15m0-15L0 15"></path>
    <path stroke="#fff" strokeWidth="5" d="M12.5 0v15M0 7.5h25"></path>
    <path stroke="#cf142b" strokeWidth="3" d="M12.5 0v15M0 7.5h25"></path>
  </svg>
);

export default EnFlag;
