import React from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import messages from './Table/messages';

const DeleteConfirmModal = ({ label, open, close, remove }) => {
  const { formatMessage } = useIntl();

  const handleDelete = () => {
    remove();
    close();
  };

  return (
    <Dialog open={open} onClose={close} onClick={e => e.stopPropagation()}>
      <DialogTitle>
        {formatMessage(messages.deleted_modal_template_text)}
        {label !== formatMessage(messages.deleted_modal_unlink_text) &&
          formatMessage(messages.deleted_modal_delete_text)}
        {formatMessage(label)} ?
      </DialogTitle>
      <DialogActions>
        <Button className="button danger" onClick={close}>
          {formatMessage(messages.deleted_modal_no)}
        </Button>
        <Button className="button" onClick={handleDelete}>
          {formatMessage(messages.deleted_modal_yes)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmModal;
