import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, IconButton } from '@material-ui/core';
import { FiBell } from 'react-icons/all';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from '../Notifications/redux/reducer';
import saga from '../Notifications/redux/saga';
import {
  getUnreadNotifications,
  readNotification,
  readAllNotifications,
  deleteNotification
} from '../Notifications/redux/actions';
import { makeSelectUnreadNotifications } from '../Notifications/redux/selectors';
import { makeSelectUser } from 'containers/App/selectors';

import useOutsideClick from 'helpers/useOutsideClick';
import messages from './messages';
import sound_effect from 'assets/short_notification.mp3';
import { activeClass } from 'helpers/activeClass';
import ShowMore from './ShowMore';
import routes from 'routes';
import Icons from 'cms/Notifications/Icons';

const key = 'cms_notifications';

const Notifications = ({ setSelected }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [showMoreActiveIndex, setShowMoreActiveIndex] = useState(null);
  const [showMoreIsVisible, setShowMoreIsVisible] = useState(false);

  const select = useRef(null);
  const toggle = () => setIsVisible(current => !current);
  useOutsideClick(select, () => isVisible && toggle());
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { unread_notifications_count, unread_notifications } = useSelector(
    makeSelectUnreadNotifications()
  );
  const user = useSelector(makeSelectUser());
  const { push } = useHistory();

  useEffect(() => {
    if (user) dispatch(getUnreadNotifications(user.id));
  }, [user, dispatch]);

  const handleReadNotification = useCallback(
    (...args) => dispatch(readNotification(...args)),
    [dispatch]
  );
  const handleDeleteNotification = useCallback(
    (...args) => dispatch(deleteNotification(...args)),
    [dispatch]
  );

  const readAll = () => {
    if (unread_notifications.length) dispatch(readAllNotifications(user.id));
  };

  const handleClick = item => {
    dispatch(handleReadNotification(user.id, item.id));
    setSelected(item);
    toggle();
  };

  return (
    <>
      <div className="notifications_select">
        <audio id="notification_audio" src={sound_effect} />
        <div className="root_icon" onClick={toggle}>
          {unread_notifications.length ? (
            <span className="length">{unread_notifications_count}</span>
          ) : null}
          <IconButton className="icon_button">
            <FiBell className="bell_icon" />
          </IconButton>
        </div>
        {isVisible && (
          <div className="select_inner" ref={select}>
            <div className="detail">
              <h2>{formatMessage(messages.notifications)}</h2>
              {unread_notifications.length ? (
                <p onClick={readAll} className="read_all">
                  {formatMessage(messages.read_all)}
                </p>
              ) : null}
            </div>
            <div className="select_outer">
              {unread_notifications.length ? (
                <div className="items">
                  {unread_notifications.map((item, index) => (
                    <div
                      key={index}
                      className={activeClass('item', !item.read_at)}
                      onClick={() => handleClick(item)}
                      onMouseEnter={() =>
                        !showMoreIsVisible && setShowMoreActiveIndex(index)
                      }
                      onMouseLeave={() =>
                        !showMoreIsVisible && setShowMoreActiveIndex(null)
                      }
                    >
                      {user && showMoreActiveIndex === index && (
                        <ShowMore
                          item={item}
                          isVisible={showMoreIsVisible}
                          setIsVisible={setShowMoreIsVisible}
                          user={user}
                          readNotification={handleReadNotification}
                          deleteNotification={handleDeleteNotification}
                        />
                      )}
                      <Icons notification_type={item.type} />
                      <div className="row">
                        <div className="title">{item.data.pricelist}</div>
                        <div className="message">{item.data.message}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="empty">{formatMessage(messages.empty_list)}</p>
              )}
              <div className="bottom_part">
                <Button
                  className="button"
                  onClick={() => {
                    push(routes.CMS_NOTIFICATIONS);
                    toggle();
                  }}
                >
                  {formatMessage(messages.view_all)}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
