import types from './constants';

export const getRatings = params => ({
  type: types.GET_RATINGS,
  params
});

export const setRatings = payload => ({
  type: types.SET_RATINGS,
  payload
});

export const getRating = id => ({
  type: types.GET_RATING,
  id
});

export const setRating = payload => ({
  type: types.SET_RATING,
  payload
});

export const getParams = () => ({
  type: types.GET_PARAMS
});

export const setParams = payload => ({
  type: types.SET_PARAMS,
  payload
});

export const approveRating = (id, payload, isSingle) => ({
  type: types.APPROVE_RATING,
  id,
  payload,
  isSingle
});

export const deleteRating = (id, goBack) => ({
  type: types.DELETE_RATINGS,
  id,
  goBack
});
