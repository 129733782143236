import React from 'react';
import PropTypes from 'prop-types';
import SkeletonElement from 'components/SkeletonElement';

const Skeleton = ({ className }) => {
  return (
    <div className="skeleton_inner">
      <div className="skeleton_wrapper header">
        <SkeletonElement type="text" />
      </div>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
        <div key={n} className={`skeleton_wrapper ${className}`}>
          <SkeletonElement type="box" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="icon" />
          <SkeletonElement type="icon" />
          <SkeletonElement type="icon" />
        </div>
      ))}
    </div>
  );
};

Skeleton.propTypes = {
  className: PropTypes.string
};

export default Skeleton;
