import types from './constants';

export const fetchUsers = params => ({
  type: types.FETCH_USERS,
  params
});

export const setUsers = payload => ({
  type: types.SET_USERS,
  payload
});

export const getUser = user_id => ({
  type: types.GET_USER,
  user_id
});

export const setUser = payload => ({
  type: types.SET_USER,
  payload
});

export const clearUserData = () => ({
  type: types.CLEAR_USER_DATA
});

export const getUserError = error => ({
  type: types.GET_USER_ERROR,
  error
});

export const createUser = (payload, setErrors) => ({
  type: types.CREATE_USER_REQUEST,
  payload,
  meta: { setErrors }
});

export const createUserSuccess = () => ({
  type: types.CREATE_USER_SUCCESS
});

export const createUserError = () => ({
  type: types.CREATE_USER_ERROR
});

export const updateUser = (user_id, payload, setErrors) => ({
  type: types.UPDATE_USER_REQUEST,
  user_id,
  payload,
  meta: { setErrors }
});

export const updateUserSuccess = () => ({
  type: types.UPDATE_USER_SUCCESS
});

export const updateUserError = () => ({
  type: types.UPDATE_USER_ERROR
});

export const deleteUser = user_id => ({
  type: types.DELETE_USER_REQUEST,
  user_id
});

export const deleteUserSuccess = () => ({
  type: types.DELETE_USER_SUCCESS
});

export const setParams = params => ({
  type: types.SET_PARAMS,
  params
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});

export const changePassword = (id, payload, setErrors) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  id,
  payload,
  meta: { setErrors }
});

export const changePasswordSuccess = () => ({
  type: types.CHANGE_PASSWORD_SUCCESS
});

export const uploadUserAvatar = (user_id, image) => ({
  type: types.UPLOAD_USER_AVATAR,
  user_id,
  image
});

export const uploadUserAvatarSuccess = () => ({
  type: types.UPLOAD_USER_AVATAR_SUCCESS
});

export const removeUserAvatar = user_id => ({
  type: types.REMOVE_USER_AVATAR,
  user_id
});

export const removeUserAvatarSuccess = () => ({
  type: types.REMOVE_USER_AVATAR_SUCCESS
});
