import produce from 'immer';
import types from './constants';

export const initialState = {
  brands: [],
  manufacturers: [],
  suppliers: [],
  categories: [],
  discountsTypes: [],
  attributes: [],
  stickerForProducts:[]
};

/* eslint-disable default-case */
const selectsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_BRANDS:
        draft.brands = action.payload;
        break;
      case types.SET_MANUFACTURERS:
        draft.manufacturers = action.payload;
        break;
      case types.SET_SUPPLIERS:
        draft.suppliers = action.payload;
        break;
      case types.SET_CATEGORIES:
        draft.categories = action.payload;
        break;
      case types.SET_DISCOUNTS_TYPES:
        draft.discountsTypes = action.payload;
        break;
        case types.SET_STICKER_FOR_PRODUCTS:
          draft.stickerForProducts = action.payload;
          break;
      case types.SET_ATTRIBUTES:
        draft.attributes = action.payload;
        break;
    }
  });

export default selectsReducer;
