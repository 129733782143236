import { defineMessages } from 'react-intl';

export const scope = 'cms_our_suppliers';

export default defineMessages({
    page_title: {
        id: `${scope}.page_title`,
        defaultMessage: 'Naši dobavljači'
    },
    name: {
        id: `${scope}.name`,
        defaultMessage: 'Ime'
    },
    connected_products: {
        id: `${scope}.connected_products`,
        defaultMessage: 'Povezani proizvodi'
    },
    unconnected_products: {
        id: `${scope}.unconnected_products`,
        defaultMessage: 'Nepovezani proizvodi'
    },
    updated_at: {
        id: `${scope}.updated_at`,
        defaultMessage: 'Ažurirano'
    }
})