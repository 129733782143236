const KEY = '[CMS_WEB_MARGINS]';

const types = {
  GET_MARGINS: `${KEY} GET_MARGINS`,
  SET_MARGINS: `${KEY} SET_MARGINS`,

  GET_MARGIN: `${KEY} GET_MARGIN`,
  SET_MARGIN: `${KEY} SET_MARGIN`,
  CLEAR_MARGIN: `${KEY} CLEAR_MARGIN`,
  GET_MARGIN_ERROR: `${KEY} GET_MARGIN_ERROR`,

  ADD_MARGIN_REQUEST: `${KEY} ADD_MARGIN_REQUEST`,
  ADD_MARGIN_SUCCESS: `${KEY} ADD_MARGIN_SUCCESS`,
  ADD_MARGIN_ERROR: `${KEY} ADD_MARGIN_ERROR`,

  UPDATE_MARGIN_REQUEST: `${KEY} UPDATE_MARGIN_REQUEST`,
  UPDATE_MARGIN_SUCCESS: `${KEY} UPDATE_MARGIN_SUCCESS`,
  UPDATE_MARGIN_ERROR: `${KEY} UPDATE_MARGIN_ERROR`,

  DELETE_MARGIN_REQUEST: `${KEY} DELETE_MARGIN_REQUEST`,
  DELETE_MARGIN_SUCCESS: `${KEY} DELETE_MARGIN_SUCCESS`,

  SET_CURRENT_PAGE: `${KEY} SET_CURRENT_PAGE`,
  SET_PER_PAGE: `${KEY} SET_PER_PAGE`,
  DELETE_PARAMS: `${KEY} DELETE_PARAMS`,

  SET_PARAMS: `${KEY} SET_PARAMS`
};

export default types;
