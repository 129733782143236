import React from 'react';
import { useIntl } from 'react-intl';
import { FaTimes } from 'react-icons/fa';
import messages from '../messages';

const PreviewFilters = ({ params, setParams }) => {
  const { formatMessage } = useIntl();

  const translateKeys = item => {
    let name = item[0];
    let value = item[1];

    if (name === 'active')
      return formatMessage(messages.filter_prev_status_active);
    if (name === 'inactive')
      return formatMessage(messages.filter_prev_status_inactive);

    if (name === 'with_image')
      return formatMessage(messages.filter_prev_with_image);
    if (name === 'without_image')
      return formatMessage(messages.filter_prev_without_image);

    if (name === 'with_attributes')
      return formatMessage(messages.filter_prev_with_attributes);
    if (name === 'without_attributes')
      return formatMessage(messages.filter_prev_without_attributes);

    if (name === 'connected')
      return formatMessage(messages.filter_prev_with_connected);
    if (name === 'disconnected')
      return formatMessage(messages.filter_prev_without_disconnected);

    if (name === 'live') return formatMessage(messages.filter_prev_live);
    if (name === 'dead') return formatMessage(messages.filter_prev_dead);

    if (name === 'supplier')
      return `${formatMessage(messages.filter_prev_supplier)}${value}`;

    if (name === 'category')
      return `${formatMessage(messages.filter_prev_category)}${value}`;

    if (name === 'web_price_from')
      return `${formatMessage(messages.filter_prev_web_price_from)}${value}`;
    if (name === 'web_price_to')
      return `${formatMessage(messages.filter_prev_web_price_to)}${value}`;

    if (name === 'entry_price_from')
      return `${formatMessage(messages.filter_prev_entry_price_from)}${value}`;
    if (name === 'entry_price_to')
      return `${formatMessage(messages.filter_prev_entry_price_to)}${value}`;

    if (name === 'discount')
      return formatMessage(messages.filter_prev_included_discount);
    if (name === 'discount_type')
      return `${formatMessage(messages.discount_type)}: ${value}`;
    if (name === 'discount_price_from')
      return `${formatMessage(
        messages.filter_prev_discount_price_from
      )}${value}`;
    if (name === 'discount_price_to')
      return `${formatMessage(messages.filter_prev_discount_price_to)}${value}`;
    if (name === 'discount_from')
      return `${formatMessage(messages.filter_prev_discount_from)}${value}`;
    if (name === 'discount_to')
      return `${formatMessage(messages.filter_prev_discount_to)}${value}`;
    if (name === 'brand') {
      return `${formatMessage(messages.brand)}:${value}`;
    }
    if (name === 'supplier') {
      return `${formatMessage(messages.suppliers)}:${value}`;
    }
    if (name === 'manufacturers') {
      const manufacturersData = JSON.parse(
        localStorage.getItem('manufacturers')
      );
      let data = [];
      const values = value.split(',');
      values.forEach(val => {
        let item = manufacturersData.find(m => String(m.id) === val);
        if (item) data.push(item.value);
      });
      return `${formatMessage(messages.manufacturers)}:${data.toString()}`;
    }
    if (name === 'category') {
      return `Kategorije: ${value}`;
    }
  };

  const classes = value => {
    if (value === 'search') {
      return 'hidden';
    } else if (value === 'page') {
      return 'hidden';
    } else if (value === 'paginate') {
      return 'hidden';
    } else {
      return 'chip';
    }
  };

  const handleDelete = value => {
    if (value[0] === 'manufacturers') {
      setParams({ ...params, manufacturers: undefined });
      return;
    }

    const { [value[0]]: tmp, ...rest } = params;
    setParams(rest);
  };

  return (
    <div className="chips_wrap">
      {!params
        ? null
        : Object.entries(params).map((value, key) => {
            if (!value[1]) return null;
            return (
              <div className={classes(value[0])} key={key}>
                <p>{translateKeys(value)}</p>
                <span onClick={() => handleDelete(value)}>
                  <FaTimes />
                </span>
              </div>
            );
          })}
    </div>
  );
};

export default PreviewFilters;
