import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import jwtDecode from 'jwt-decode';

import { enqueueSnackbar } from 'containers/Notifier/actions';
import { logout } from 'containers/App/actions';
import { makeSelectToken } from 'containers/App/selectors';

import { getItem } from './localStorage';
import { baseApiUrl } from '../env';
import messages from 'messages';

const api = axios.create({
  baseURL: baseApiUrl
});

api.interceptors.response.use(
  response => mapKeys(response.data, (_, key) => camelCase(key)),
  error => Promise.reject(error.response)
);

export default function* request({ url, method, data, headers = {}, params }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      if (Date.now() / 1000 >= jwtDecode(token).exp) {
        yield put(logout());
      }

      headers.Authorization = `Bearer ${token}`;
    }

    headers['Content-Language'] = getItem('language') || 'sr';

    return yield call(api, { method, url, headers, data, params });
  } catch (error) {
    if (error.status === 403) {
      yield put(enqueueSnackbar({ message: messages.unauthorized }, 'danger'));
    }
    if (error.status === 500) {
      yield put(enqueueSnackbar({ message: messages.server_error }, 'danger'));
    }
    throw error;
  }
}
