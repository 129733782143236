import React from 'react';
import NavLink from './NavLink';
import Dropdown from './Dropdown';
import messages from './messages';
import { frontend } from './groups';
import { BiMinusFront } from 'react-icons/all';
import HomeDropdown from './HomeDropdown';

const FrontendDropdown = ({ closeSidebar }) => (
  <Dropdown icon={<BiMinusFront />} label={messages.frontend}>
    {frontend.map((item, index) => (
      <NavLink
        key={index}
        name={item.name}
        icon={item.icon}
        route={item.url}
        role={item.role}
        module={item.module}
        closeSidebar={closeSidebar}
      />
    ))}
    <HomeDropdown closeSidebar={closeSidebar} />
  </Dropdown>
);

export default FrontendDropdown;
