import Echo from 'laravel-echo';

window.io = require('socket.io-client');

const echo_instance = token =>
  new Echo({
    broadcaster: 'socket.io',
    host: 'https://api.jakovsmartsolutions.com:6001',
    transports: ['websocket', 'polling', 'flashsocket'],
    auth: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });

export default echo_instance;
