import produce from 'immer';
import types from './constants';

export const initialState = {
  suppliers: {
    data:null,meta:null
  }
};

/* eslint-disable default-case */
const _suppliers = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_SUPPLIERS:
        draft.suppliers = action.payload;
        break;
    }
  });

export default _suppliers;
