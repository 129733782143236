import Yup from 'utils/validations';
import messages from './messages';

export const createProductSchema = Yup.object().shape({
  sku: Yup.string()
    .max(15, messages.yup_validate_sku_max)
    .required(messages.yup_validate_sku_required),
  name: Yup.string()
    .min(2, messages.yup_validate_name_min)
    .max(1200, messages.yup_validate_name_max)
    .required(messages.yup_validate_name_required),
  entry_price: Yup.number()
    .min(0.01, messages.yup_validate_entry_price_min)
    .required(messages.yup_validate_entry_price_required),
  description: Yup.string()
    .min(2, messages.yup_validate_description_min),
  categories: Yup.array().required(messages.yup_validate_categories_required)
});

export const updateProductSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, messages.yup_validate_name_min)
    .max(1200, messages.yup_validate_name_max)
    .required(messages.yup_validate_name_required),
  entry_price: Yup.number()
    .min(0.01, messages.yup_validate_entry_price_min)
    .required(messages.yup_validate_entry_price_required),
  description: Yup.string()
    .min(2, messages.yup_validate_description_min),
  categories: Yup.array().required(messages.yup_validate_categories_required)
});

export const updateDiscountsSchema=(isActionType=true) => {
  if(isActionType) {
    return Yup.object().shape({
      discount_type_id: Yup.string().required(
        messages.yup_validate_discount_type_required
      ),
      from: Yup.date().required(messages.yup_validate_discount_from_required),
      to: Yup.date().required(messages.yup_validate_discount_to_required)
    })
  }
  return Yup.object().shape({
    price: Yup.number().required("Fiksna akcijska cena je obavezno polje"),
    from: Yup.date().required(messages.yup_validate_discount_from_required),
    to: Yup.date().required(messages.yup_validate_discount_to_required)
  })
};

export const createAttributeSchema = Yup.object().shape({
  name: Yup.string().required(messages.yup_validate_name_required)
});

export const productAttributes = Yup.object().shape({
  attribute_value_id: Yup.mixed().required(
    messages.yup_validate_attribute_value_id_required
  )
});

export const attributeValueSchema = Yup.object().shape({
  value: Yup.string()
    .min(1, messages.yup_validate_attribute_value_min)
    .max(255, messages.yup_validate_attribute_value_max)
    .required(messages.yup_validate_attribute_value_required)
});
