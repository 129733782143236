const KEY = '[CMS_BANNERS]';

const types = {
  GET_BANNERS: `${KEY} GET_BANNERS`,
  SET_BANNERS: `${KEY} SET_BANNERS`,
  CHANGE_ORDER_REQUEST: `${KEY} CHANGE_ORDER_REQUEST`,

  SET_PARAMS: `${KEY} SET_PARAMS`,
  DELETE_PARAMS: `${KEY} DELETE_PARAMS`,
  SET_POSITION: `${KEY} SET_POSITION`,

  GET_BANNER: `${KEY} GET_BANNER`,
  SET_BANNER: `${KEY} SET_BANNER`,
  GET_BANNER_ERROR: `${KEY} GET_BANNER_ERROR`,

  GET_BANNERS_POSITIONS: `${KEY} GET_BANNERS_POSITIONS`,
  SET_BANNERS_POSITIONS: `${KEY} SET_BANNERS_POSITIONS`,

  CREATE_BANNER: `${KEY} CREATE_BANNER`,
  UPDATE_BANNER: `${KEY} UPDATE_BANNER`,
  UPDATE_BANNER_PHOTO: `${KEY} UPDATE_BANNER_PHOTO`,
  UPDATE_BANNER_MOBILE_PHOTO: `${KEY} UPDATE_BANNER_MOBILE_PHOTO`,
  DELETE_BANNER: `${KEY} DELETE_BANNER`
};

export default types;
