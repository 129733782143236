import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, Dialog } from '@material-ui/core';

import {
  getPassProducts,
  setPassProducts,
  postPassProducts
} from 'cms/Products/redux/actions';
import { makeSelectPassedProducts } from 'cms/Products/redux/selectors';
import messages from 'cms/Products/messages';
import Alert from './Alert';
import CategorySelect from './CategorySelect';

const PassProducts = ({
  ids,
  checkAll,
  params,
  open,
  close,
  openFilterMenu,
  setCategoriesSelectVisible
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const items = useSelector(makeSelectPassedProducts());

  const handleSubmit = useCallback(
    (...args) => dispatch(getPassProducts(...args)),
    [dispatch]
  );

  const handleClose = () => {
    dispatch(setPassProducts([]));
    setSelectedCategory(null);
    close();
  };

  const handleCreate = active => {
    const { paginate, page: tmp, ...rest } = params;
    let payload = {
      category_id: selectedCategory.id,
      active
    };
    if (ids.length) payload.products = ids;
    dispatch(postPassProducts(payload, ids.length ? null : rest, close));
  };

  return (
    <>
      {(ids.length || checkAll) && params.category ? (
        <Dialog
          open={open}
          onClose={handleClose}
          className="pass_products_dialog"
        >
          <CategorySelect
            handleSubmit={handleSubmit}
            ids={ids}
            params={params}
            selected={selectedCategory}
            setSelected={setSelectedCategory}
            handleClose={handleClose}
          />
          {items.length ? (
            <div className="list_of_attributes">
              <h3>{formatMessage(messages.attributes_missing)}</h3>
              <ul>
                {items.map(item => (
                  <li key={item.id}>{item.name}</li>
                ))}
                <h3 className="mt-20">{formatMessage(messages.auto)}</h3>
                <Button className="button" onClick={() => handleCreate(true)}>
                  {formatMessage(messages.create_and_activate_attirbutes)}
                </Button>
                <Button className="button" onClick={() => handleCreate(false)}>
                  {formatMessage(messages.create_and_deactivate_attirbutes)}
                </Button>
              </ul>
            </div>
          ) : null}
          <div className="buttons">
            <Button className="button default" onClick={handleClose}>
              {formatMessage(messages.close)}
            </Button>
          </div>
        </Dialog>
      ) : (
        <Alert
          open={open}
          close={handleClose}
          params={params}
          openFilterMenu={openFilterMenu}
          setCategoriesSelectVisible={setCategoriesSelectVisible}
        />
      )}
    </>
  );
};

export default PassProducts;
