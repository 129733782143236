import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './redux/reducer';
import saga from './redux/saga';
import {
  getProducts,
  setParams,
  updateDescription,
  disconnectProduct,
  massUpdateDiscounts,
  uploadSticker,
  uploadAction
} from './redux/actions';
import { makeSelectProducts, makeSelectParams } from './redux/selectors';
import { ReactComponent as FiltersIcon } from 'assets/images/icons/filters.svg';
import Table from './Table';
import Filters from './Filters';
import PreviewFilters from './Filters/Preview';
import Wrap from 'cms/components/Wrap';
import MoreTools from './MoreTools';
import messages from './messages';
import Skeleton from './Skeleton';
import Axios from 'axios';
import styled from 'styled-components';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { baseApiUrl, baseURL } from 'env';

const Tabs = styled.div`
  background: #f4f4f4;
`;

const TabPanel = styled(Button)`
  padding: 10px;
  ${props =>
    props.active
      ? `
padding: 10px 20px;
font-size: 16px;
background:#fff;
font-weight:600;`
      : `
background:#f4f4f4
`}
`;
const key = 'visibleOnSite';

const Products = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleOffer, setIsVisibleEoffer] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [categoriesSelectVisible, setCategoriesSelectVisible] = useState(false);
  const [selectCategoryId, setSelectCategoryId] = useState(null);
  const [value, setValue] = React.useState(1);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const products = useSelector(makeSelectProducts());
  const params = useSelector(makeSelectParams());
  const { data, meta } = products;
  const [eponudaMeta, setEponudaMeta] = useState(null);
  const [productsIds, setProductIds] = useState([]);
  const [ePonudaFeeds, setEPonudaFeeds] = useState([]);
  const [eponudaParams, setEponudaParams] = useState({});

  useEffect(() => {
    dispatch(getProducts(params));
  }, [dispatch, params]);
  useEffect(() => {
    if (products && products.data && products.data.length) setAllProductsIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products && products.data && products.data.length, params]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEOfferFeed = async () => {
    setProductIds([]);
    const url = new URL(`${baseURL}/admin/eponuda-feed`);
    if (eponudaParams) {
      Object.keys(eponudaParams).forEach(param =>
        url.searchParams.append(param, eponudaParams[param])
      );
    }
    const response = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.token.split('"').join('')}`
      }
    });
    if (response.status === 200 && response.data?.data) {
      setEPonudaFeeds(response.data.data);
      setEponudaMeta(response.data.meta);
      const tmpData = [];
      response.data.data.forEach(item => tmpData.push(item.id));
      setProductIds(state => [...state, ...tmpData]);
    }
  };
  useEffect(() => {
    if (value === 2) {
      getEOfferFeed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, eponudaParams]);
  const paramsSetter = useCallback((...args) => dispatch(setParams(...args)), [
    dispatch
  ]);

  const handleUpdateDescription = useCallback(
    (...args) => dispatch(updateDescription(...args)),
    [dispatch]
  );
  const handleDisconnectProduct = useCallback(
    (...args) => dispatch(disconnectProduct(...args)),
    [dispatch]
  );
  const handleMassUpdateDiscounts = useCallback(
    (...args) => dispatch(massUpdateDiscounts(...args)),
    [dispatch]
  );

  const toggleFiltersMenu = () => setIsVisible(!isVisible);
  const toggleFiltersMenuEoffer = () => setIsVisibleEoffer(!isVisibleOffer);

  const clearData = () => {
    setCheckedItems([]);
    setCheckAll(false);
  };
  const setStiker = data => {
    dispatch(uploadSticker(data));
  };
  // const setAction = (data)=>{
  //   dispatch(uploadAction(data))
  // }
  const setAction = useCallback((...args) => dispatch(uploadAction(...args)), [
    dispatch
  ]);
  const setAllProductsIds = async () => {
    setProductIds([]);
    // let pagination = params.paginate || 20;
    let pagination = 100;
    let productsArray = [];
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.token.split('"').join('')}`
      }
    };
    products.data &&
      products.data.forEach(product => {
        productsArray.push(product.id);
      });
    let promises = [];
    for (let index = 2; index <= 10; index++) {
      const urlparams = new URLSearchParams({
        ...params,
        paginate: pagination,
        page: index
      }).toString();
      promises.push(
        Axios.get(
          `${baseApiUrl}/admin/products?include=mainCategory&visible=1&active=1&${urlparams}`,
          config
        )
      );
    }
    const datas = await Promise.all(promises);
    datas.forEach(promise => {
      promise.data.data.forEach(prod => productsArray.push(prod.id));
    });
    setProductIds(productsArray);
  };

  const handleSend = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.token.split('"').join('')}`
      }
    };
    const formData = new FormData();
    if (value === 1) {
      checkedItems.forEach(item => formData.append('ids_true[]', item));
    } else {
      checkedItems.forEach(item => formData.append('ids_false[]', item));
    }
    const response = await Axios.post(
      `${baseURL}/admin/eponuda-feed`,
      formData,
      config
    );
    if (response.status < 400) {
      setCheckedItems([]);
      if (value === 1) dispatch(getProducts(params));
      else getEOfferFeed();
      if (response?.data?.message)
        dispatch(enqueueSnackbar({ message: response.data.message }));
    }
  };

  const setParamsData = data => {
    let prm = {};
    const { manufacturers, brands, suppliers, categories, ...rest } = data;
    if (data.hasOwnProperty('manufacturers')) {
      if (data.manufacturers) {
        let manufacturers = [];
        if (typeof data.manufacturers === 'string') {
          manufacturers = [...data.manufacturers.split(',')];
        } else {
          data.manufacturers.forEach(element => {
            manufacturers.push(element.id);
          });
        }
        prm = {
          ...prm,
          manufacturers: manufacturers.toString()
        };
      } else {
        prm = {
          ...prm,
          manufacturers: undefined
        };
      }
    }
    if (data.hasOwnProperty('suppliers')) {
      if (data.suppliers) {
        let suppliers = [];
        if (typeof data.suppliers === 'string') {
          suppliers = [...data.suppliers.split(',')];
        } else {
          data.suppliers.forEach(element => {
            suppliers.push(element.id);
          });
        }
        prm = {
          ...prm,
          suppliers: suppliers.toString()
        };
      } else {
        prm = {
          ...prm,
          suppliers: undefined
        };
      }
    }
    if (data.hasOwnProperty('categories')) {
      if (data.categories) {
        let categories = [];
        if (typeof data.categories === 'string') {
          categories = [...data.categories.split(',')];
        } else {
          data.categories.forEach(element => {
            categories.push(element.id);
          });
        }
        prm = {
          ...prm,
          categories: categories.toString()
        };
      } else {
        prm = {
          ...prm,
          categories: undefined
        };
      }
    }
    if (data.hasOwnProperty('brands')) {
      if (data.brands) {
        let brands = [];
        if (typeof data.brands === 'string') {
          brands = [...data.brands.split(',')];
        } else {
          data.brands.forEach(element => {
            brands.push(element.id);
          });
        }
        prm = {
          ...prm,
          brands: brands.toString()
        };
      } else {
        prm = {
          ...prm,
          brands: undefined
        };
      }
    }
    prm = { ...prm, ...rest };
    setEponudaParams(prm);
  };
  return (
    <>
      <Tabs>
        <TabPanel active={value === 1} onClick={() => setValue(1)}>
          PROIZVODI
        </TabPanel>
        <TabPanel active={value === 2} onClick={() => setValue(2)}>
          E-PONUDA PROIZVODI
        </TabPanel>
      </Tabs>
      {value === 1 ? (
        <>
          {isVisible && (
            <Filters
              close={toggleFiltersMenu}
              setParams={paramsSetter}
              params={params}
              categoriesSelectVisible={categoriesSelectVisible}
              setCategoriesSelectVisible={setCategoriesSelectVisible}
              setSelectCategoryId={setSelectCategoryId}
            />
          )}
          <Wrap
            classes="product_page_wrap_eoffer"
            pageTitle={messages.page_title}
            data={data}
            meta={meta}
            params={params}
            type="cms"
            setParams={paramsSetter}
            skeleton={<Skeleton className="products" />}
            headerRightContent={
              <>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: '10px' }}
                  disabled={!checkedItems.length}
                  onClick={handleSend}
                >
                  Pošalji
                </Button>
                <div className="align_row table_helper_icons">
                  <MoreTools
                    params={params}
                    ids={checkedItems}
                    handleUpdate={handleUpdateDescription}
                    clear={clearData}
                    checkAll={checkAll}
                    openFilterMenu={() => setIsVisible(true)}
                    setCategoriesSelectVisible={setCategoriesSelectVisible}
                    massUpdateDiscounts={handleMassUpdateDiscounts}
                    selectCategoryId={selectCategoryId}
                    setSticker={setStiker}
                    setAction={setAction}
                  />
                  <IconButton
                    className="icon_button filter_icon"
                    onClick={toggleFiltersMenu}
                  >
                    <FiltersIcon />
                  </IconButton>
                </div>
              </>
            }
            middleContent={
              <PreviewFilters params={params} setParams={paramsSetter} />
            }
            tableContent={
              <Table
                data={data}
                ids={checkedItems}
                setIDs={setCheckedItems}
                productsIds={productsIds}
                checkAll={checkAll}
                setCheckAll={setCheckAll}
                handleDisconnectProduct={handleDisconnectProduct}
              />
            }
          />
        </>
      ) : (
        <>
          {isVisibleOffer && (
            <Filters
              close={toggleFiltersMenuEoffer}
              setParams={paramsdata => {
                setParamsData(paramsdata);
              }}
              params={eponudaParams}
              categoriesSelectVisible={categoriesSelectVisible}
              setCategoriesSelectVisible={setCategoriesSelectVisible}
              setSelectCategoryId={setSelectCategoryId}
            />
          )}
          <Wrap
            classes="product_page_wrap_eoffer"
            pageTitle={messages.page_title}
            data={ePonudaFeeds}
            meta={eponudaMeta}
            params={eponudaParams}
            type="cms"
            setParams={paramsdata => {
              setParamsData(paramsdata);
            }}
            skeleton={<Skeleton className="products" />}
            headerRightContent={
              <>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: '10px' }}
                  disabled={!checkedItems.length}
                  onClick={handleSend}
                >
                  Pošalji
                </Button>
                <div className="align_row table_helper_icons">
                  <MoreTools
                    params={eponudaParams}
                    ids={checkedItems}
                    handleUpdate={handleUpdateDescription}
                    clear={clearData}
                    checkAll={checkAll}
                    openFilterMenu={() => setIsVisible(true)}
                    setCategoriesSelectVisible={setCategoriesSelectVisible}
                    massUpdateDiscounts={handleMassUpdateDiscounts}
                    selectCategoryId={selectCategoryId}
                    setSticker={setStiker}
                    setAction={setAction}
                  />
                  <IconButton
                    className="icon_button filter_icon"
                    onClick={toggleFiltersMenuEoffer}
                  >
                    <FiltersIcon />
                  </IconButton>
                </div>
              </>
            }
            middleContent={
              <PreviewFilters
                params={eponudaParams}
                setParams={paramsdata => {
                  setParamsData(paramsdata);
                }}
              />
            }
            tableContent={
              <Table
                data={ePonudaFeeds}
                ids={checkedItems}
                setIDs={setCheckedItems}
                productsIds={productsIds}
                checkAll={checkAll}
                setCheckAll={setCheckAll}
                handleDisconnectProduct={handleDisconnectProduct}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default Products;
