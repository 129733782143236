import { defineMessages } from 'react-intl';

export const scope = 'cms_navbar';

export default defineMessages({
  lang_en: {
    id: `${scope}.lang_en`,
    defaultMessage: 'English'
  },
  lang_sr: {
    id: `${scope}.lang_sr`,
    defaultMessage: 'Serbian'
  },
  lang_ch: {
    id: `${scope}.lang_ch`,
    defaultMessage: 'Chinese'
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Odjavi se'
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifikacije'
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'Nove'
  },
  read_all: {
    id: `${scope}.read_all`,
    defaultMessage: 'Označi sve kao pročitano'
  },
  view_all: {
    id: `${scope}.view_all`,
    defaultMessage: 'Vidi sve'
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Obriši'
  },
  mark_as_read: {
    id: `${scope}.mark_as_read`,
    defaultMessage: 'Označi kao pročitano'
  },
  empty_list: {
    id: `${scope}.empty_list`,
    defaultMessage: 'Trenutno nema ni jedne notifikacije.'
  }
});
