import produce from 'immer';
import types from './constants';

export const initialState = {
  notifications: [],
  unreadNotifications: {
    unread_notifications_count: 0,
    unread_notifications: []
  }
};

/* eslint-disable default-case */
const cmsNotificationsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_NOTIFICATIONS:
        draft.notifications = action.payload;
        break;
      case types.SET_UNREAD_NOTIFICATIONS:
        draft.unreadNotifications = action.payload;
        break;
    }
  });

export default cmsNotificationsReducer;
