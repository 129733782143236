const SELECTS = '[CMS_SELECTS]';

const types = {
  FETCH_BRANDS: `${SELECTS} FETCH_BRANDS`,
  SET_BRANDS: `${SELECTS} SET_BRANDS`,

  FETCH_MANUFACTURERS: `${SELECTS} FETCH_MANUFACTURERS`,
  SET_MANUFACTURERS: `${SELECTS} SET_MANUFACTURERS`,

  FETCH_SUPPLIERS: `${SELECTS} FETCH_SUPPLIERS`,
  SET_SUPPLIERS: `${SELECTS} SET_SUPPLIERS`,

  FETCH_CATEGORIES: `${SELECTS} FETCH_CATEGORIES`,
  FETCH_CATEGORIES_WITH_SUB: `${SELECTS} FETCH_CATEGORIES_WITH_SUB`,
  SET_CATEGORIES: `${SELECTS} SET_CATEGORIES`,

  FETCH_DISCOUNTS_TYPES: `${SELECTS} FETCH_DISCOUNTS_TYPES`,
  SET_DISCOUNTS_TYPES: `${SELECTS} SET_DISCOUNTS_TYPES`,
  FETCH_STICKER_FOR_PRODUCTS: `${SELECTS} FETCH_STICKER_FOR_PRODUCTS`,
  SET_STICKER_FOR_PRODUCTS: `${SELECTS} SET_STICKER_FOR_PRODUCTS`,

  FETCH_ATTRIBUTES: `${SELECTS} FETCH_ATTRIBUTES`,
  SET_ATTRIBUTES: `${SELECTS} SET_ATTRIBUTES`
};

export default types;
