const USERS = '[CMS_USERS]';

const types = {
  FETCH_USERS: `${USERS} FETCH_USERS`,
  SET_USERS: `${USERS} SET_USERS`,

  GET_USER: `${USERS} GET_USER`,
  SET_USER: `${USERS} SET_USER`,
  CLEAR_USER_DATA: `${USERS} CLEAR_USER_DATA`,
  GET_USER_ERROR: `${USERS} GET_USER_ERROR`,

  CREATE_USER_REQUEST: `${USERS} CREATE_USER_REQUEST`,
  CREATE_USER_SUCCESS: `${USERS} CREATE_USER_SUCCESS`,
  CREATE_USER_ERROR: `${USERS} CREATE_USER_ERROR`,

  UPDATE_USER_REQUEST: `${USERS} UPDATE_USER_REQUEST`,
  UPDATE_USER_SUCCESS: `${USERS} UPDATE_USER_SUCCESS`,
  UPDATE_USER_ERROR: `${USERS} UPDATE_USER_ERROR`,

  DELETE_USER_REQUEST: `${USERS} DELETE_USER_REQUEST`,
  DELETE_USER_SUCCESS: `${USERS} DELETE_USER_SUCCESS`,

  SET_PARAMS: `${USERS} SET_PARAMS`,
  DELETE_PARAMS: `${USERS} DELETE_PARAMS`,

  CHANGE_PASSWORD_REQUEST: `${USERS} CHANGE_PASSWORD_REQUEST`,
  CHANGE_PASSWORD_SUCCESS: `${USERS} CHANGE_PASSWORD_SUCCESS`,

  UPLOAD_USER_AVATAR: `${USERS} UPLOAD_USER_AVATAR`,
  UPLOAD_USER_AVATAR_SUCCESS: `${USERS} UPLOAD_USER_AVATAR_SUCCESS`,

  REMOVE_USER_AVATAR: `${USERS} REMOVE_USER_AVATAR`,
  REMOVE_USER_AVATAR_SUCCESS: `${USERS} REMOVE_USER_AVATAR_SUCCESS`
};

export default types;
