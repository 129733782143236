import React from 'react';
import { useIntl } from 'react-intl';
import Paginate from './Table/Paginate';
import Search from './Table/Search';
import TableWrapper from './Table/TableWrapper';
import PageTitle from 'helpers/PageTitle';
import styled from 'styled-components';
import messages from './messages';
const Text = styled.p`
  padding-top: 20px;
  text-align: right;
  font-weight: 400;
  font-size: 14px;
`;
const Wrap = ({
  classes,
  pageTitle,
  data,
  meta,
  params,
  setParams,
  setCurrentPage,
  isInline,
  headerLeftContent,
  headerRightContent,
  middleContent,
  tableContent,
  skeleton,
  isMain,
  setInclude,
  disableSearchFocus,
  setSearch,
  isVisiblePagination = true,
  isVisibleSearch = true,
  helperVisible = true,
  suppliers = false,
  type
}) => {
  const { formatMessage } = useIntl();

  return (
    <section className={`cms_page ${classes && classes}`}>
      {pageTitle && <PageTitle name={formatMessage(pageTitle)} />}
      <div className="box_holder">
        <div className="table_helper">
          {helperVisible ? (
            <div className="align_row">
              <Paginate
                params={params}
                setParams={setParams}
                isInline={isInline || suppliers}
              />
              {headerLeftContent}
            </div>
          ) : null}
          {(!isInline || suppliers) && (
            <div className="align_row">
              {headerRightContent}
              {isVisibleSearch ? (
                <Search
                  params={params}
                  setParams={suppliers ? setSearch : setParams}
                  isInline={isInline}
                  isMain={isMain}
                  setInclude={setInclude}
                  disableSearchFocus={disableSearchFocus}
                />
              ) : null}
            </div>
          )}
        </div>
        {meta && !!meta.total && (
          <Text>
            {`${formatMessage(messages.total)}: ${meta.total} ${
              suppliers
                ? formatMessage(messages.suppliers)
                : formatMessage(messages.products)
            }`}
          </Text>
        )}
        {middleContent}
        {data &&
        (tableContent?.props?.productsIds
          ? tableContent?.props?.productsIds.length
          : true) ? (
          <TableWrapper
            data={data}
            type={type}
            params={params}
            setCurrentPage={isInline ? setCurrentPage : setParams}
            meta={meta}
            isInline={isInline}
            isVisiblePagination={isVisiblePagination}
          >
            {tableContent}
          </TableWrapper>
        ) : (
          skeleton
        )}
      </div>
    </section>
  );
};

export default Wrap;
