import types from './constants';

export const setParams = payload => ({
  type: types.SET_PARAMS,
  payload
});

export const clearParams = () => ({
  type: types.CLEAR_PARAMS
});

export const changeOrder = payload => ({
  type: types.CHANGE_ORDER_REQUEST,
  payload
});

export const fetchCategories = (payload, include) => ({
  type: types.FETCH_CATEGORIES,
  payload,
  include
});

export const setCategories = payload => ({
  type: types.SET_CATEGORIES,
  payload
});

export const fetchSingleCategory = payload => ({
  type: types.FETCH_SINGLE_CATEGORY,
  payload
});

export const setSingleCategory = payload => ({
  type: types.SET_SINGLE_CATEGORY,
  payload
});

export const clearSingleCategory = () => ({
  type: types.CLEAR_SINGLE_CATEGORY
});

export const fetchSingleCategoryError = error => ({
  type: types.FETCH_SINGLE_CATEGORY_ERROR,
  error
});

export const addCategory = (payload, setErrors) => ({
  type: types.ADD_CATEGORY_REQUEST,
  payload,
  meta: { setErrors }
});

export const addCategorySuccess = () => ({
  type: types.ADD_CATEGORY_SUCCESS
});

export const addCategoryError = () => ({
  type: types.ADD_CATEGORY_ERROR
});

export const updateCategory = (id, payload, setErrors) => ({
  type: types.UPDATE_CATEGORY_REQUEST,
  id,
  payload,
  meta: { setErrors }
});

export const updateCategorySuccess = () => ({
  type: types.UPDATE_CATEGORY_SUCCESS
});

export const updateCategoryError = () => ({
  type: types.UPDATE_CATEGORY_ERROR
});

export const deleteCategory = (payload, items, setItems) => ({
  type: types.DELETE_CATEGORY_REQUEST,
  payload,
  items,
  setItems
});

export const addAttribute = (id, payload, setErrors, resetForm) => ({
  type: types.ADD_ATTRIBUTE_REQUEST,
  id,
  payload,
  meta: { setErrors, resetForm }
});

export const updateAttribute = (category_id, payload, setErrors, close) => ({
  type: types.UPDATE_ATTRIBUTE_REQUEST,
  category_id,
  payload,
  meta: { setErrors },
  close
});

export const deleteAttribute = (category_id, payload) => ({
  type: types.DELETE_ATTRIBUTE_REQUEST,
  category_id,
  payload
});

export const changeAttributeOrderTable = (id, payload) => ({
  type: types.CHANGE_ATTRIBUTE_ORDER_TABLE_REQUEST,
  id,
  payload
});

export const getAttributeValues = (id, params) => ({
  type: types.GET_ATTRIBUTE_VALUES,
  id,
  params
});

export const setAttributeValues = payload => ({
  type: types.SET_ATTRIBUTE_VALUES,
  payload
});

export const addNewAttributeValue = (
  category_id,
  attribute_id,
  payload,
  close,
  setErrors
) => ({
  type: types.ADD_NEW_ATTRIBUTE_VALUE_REQUEST,
  category_id,
  attribute_id,
  payload,
  close,
  meta: { setErrors }
});

export const addAttributeValue = (category_id, payload, close, setErrors) => ({
  type: types.ADD_ATTRIBUTE_VALUE_REQUEST,
  category_id,
  payload,
  close,
  meta: { setErrors }
});

export const updateAttributeValue = (
  category_id,
  payload,
  close,
  setErrors
) => ({
  type: types.UPDATE_ATTRIBUTE_VALUE_REQUEST,
  category_id,
  payload,
  close,
  meta: { setErrors }
});

export const deleteAttributeValue = (category_id, payload) => ({
  type: types.DELETE_ATTRIBUTE_VALUE,
  category_id,
  payload
});

export const changeAttributeValuesOrder = (category_id, payload) => ({
  type: types.CHANGE_ATTRIBUTE_VALUES_ORDER,
  category_id,
  payload
});

export const createAttribute = (
  category_id,
  payload,
  setErrors,
  resetForm
) => ({
  type: types.CREATE_ATTRIBUTE_REQUEST,
  category_id,
  payload,
  meta: {
    setErrors,
    resetForm
  }
});

export const updateImage = (category_id, image) => ({
  type: types.UPDATE_IMAGE,
  category_id,
  image
});

export const deleteImage = category_id => ({
  type: types.DELETE_IMAGE,
  category_id
});

export const updateDeclaration = (category_id, payload, setErrors) => ({
  type: types.UPDATE_DECLARATION,
  category_id,
  payload,
  meta: {
    setErrors
  }
});

export const changeAttributeStatus = (category_id, attribute_id, payload) => ({
  type: types.CHANGE_ATTRIBUTE_STATUS,
  category_id,
  attribute_id,
  payload
});

export const changeAttributeValueStatus = (
  category_id,
  attribute_id,
  payload
) => ({
  type: types.CHANGE_ATTRIBUTE_VALUE_STATUS,
  category_id,
  attribute_id,
  payload
});
