/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@material-ui/core';
import './styles.scss';
import Axios from 'axios';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { CgArrowTopRight } from 'react-icons/cg';
import styled from 'styled-components';
import Table from './Table';
import TablePrice from './TablePrice';
import { baseURL } from 'env';
const Header = styled.h1`
  font-size: 48px;
  color: lightgray;
  text-align: center;
  margin-top: 100px;
`;
const Tabs = styled.div`
  background: #f4f4f4;
`;

const TabPanel = styled(Button)`
  padding: 10px;
  ${props =>
    props.active
      ? `
    padding: 10px 20px;
    font-size: 16px;
    background:#fff;
    font-weight:600;`
      : `
    background:#f4f4f4
    `}
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormWrapper = styled.div``;
const Upload = styled.input`
  display: none;
`;
const EOfferImport = () => {
  const initValues = {
    relative_to: null,
    format: null,
    value: '',
    irrelevant: []
  };
  const [value, setValue] = useState(1);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const [products, setProducts] = useState([]);
  console.log(
    '🚀 ~ file: index.js ~ line 65 ~ EOfferImport ~ products',
    products
  );
  const [ok, setOk] = useState([]);
  const [nok, setNok] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [state, setState] = useState(initValues);
  const fileRef = useRef();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.token.split('"').join('')}`
    }
  };
  const getProducts = async () => {
    const response = await Axios.get(`${baseURL}/admin/eponuda-price`, config);
    if (response.status === 200) setProducts(response.data?.data);
  };
  useEffect(() => {
    getProducts();
  }, []);
  const handleUpload = () => fileRef?.current?.click();
  const handleUploadFIle = async e => {
    setState(initValues);
    setCompanies([]);
    const tmpfile = e.target.files[0];
    const formData = new FormData();
    formData.append('eponudaCSV', tmpfile);
    const response = await Axios.post(
      `${baseURL}/admin/eponuda`,
      formData,
      config
    );
    if (response.status === 200) setFile(tmpfile);
  };
  const getCompanies = async () => {
    const response = await Axios.get(
      `${baseURL}/admin/eponuda-competitors `,
      config
    );
    if (response.status === 200) setCompanies(response.data);
  };

  const handleShow = async (tmpData = null) => {
    const url = new URLSearchParams();
    url.append('relative_to', state.relative_to);
    url.append('format', state.format);
    url.append('value', state.value);
    url.append('irrelevant[]', state.irrelevant);
    if (tmpData) {
      if (tmpData.category) url.append('category', tmpData.category);
      if (tmpData.supplier) url.append('supplier', tmpData.supplier);
      if (tmpData.brands) url.append('brand', tmpData.brand);
    }
    const response = await Axios.get(`${baseURL}/admin/eponuda?${url}`, config);
    if (response.data.ok) setOk(response.data.ok);
    if (response.data.nok) setNok(response.data.nok);
    setShow(true);
    setTimeout(() => {
      document.getElementById('oknok-table').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }, 100);
  };
  useEffect(() => {
    if (file) {
      getCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);
  const handleShowDisable = !state.format || !state.relative_to || !state.value;
  return (
    <>
      <Tabs>
        <TabPanel active={value === 1} onClick={() => setValue(1)}>
          E-ponuda
        </TabPanel>
        <TabPanel active={value === 2} onClick={() => setValue(2)}>
          E-ponuda cene
        </TabPanel>
      </Tabs>
      <div className="box_holder cms_content">
        {value === 1 ? (
          <>
            <FlexContainer>
              <Button
                color="primary"
                variant={'contained'}
                onClick={handleUpload}
              >
                Unesi csv
              </Button>
            </FlexContainer>
            <Upload
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
              onChange={handleUploadFIle}
              ref={fileRef}
            />
            {file ? (
              <FormWrapper>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <InputLabel
                      style={{ marginBottom: '10px' }}
                      id="demo-multiple-chip-label"
                    >
                      Formiraj cenu u odnosu na
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={state.relative_to}
                        name="label"
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            value={state.relative_to}
                          />
                        }
                        onChange={e =>
                          setState(state => ({
                            ...state,
                            relative_to: e.target.value
                          }))
                        }
                      >
                        <MenuItem key="eponuda" value="eponuda">
                          E ponuda
                        </MenuItem>
                        <MenuItem key="entry" value="entry">
                          Ulazna
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel
                      style={{ marginBottom: '10px' }}
                      id="demo-multiple-chip-label"
                    >
                      Format
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={state.format}
                        name="label"
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            value={state.format}
                          />
                        }
                        onChange={e =>
                          setState(state => ({
                            ...state,
                            format: e.target.value
                          }))
                        }
                      >
                        <MenuItem key="%" value="%">
                          %
                        </MenuItem>
                        <MenuItem key="RSD" value="RSD">
                          RSD
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel
                      style={{ marginBottom: '10px' }}
                      id="demo-multiple-chip-label"
                    >
                      Vrednost
                    </InputLabel>
                    <FormControl fullWidth>
                      <TextField
                        value={state.value}
                        onChange={e => {
                          e.persist();
                          setState(state => ({
                            ...state,
                            value: e.target.value
                          }));
                        }}
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel
                      style={{ marginBottom: '10px' }}
                      id="demo-multiple-chip-label"
                    >
                      Zanemari
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={state.irrelevant}
                        name="label"
                        multiple
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            value={state.irrelevant}
                          />
                        }
                        onChange={e =>
                          setState(state => ({
                            ...state,
                            irrelevant: e.target.value
                          }))
                        }
                      >
                        {companies?.length
                          ? companies.map(company => {
                              return (
                                <MenuItem key={company} value={company}>
                                  {company}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <FlexContainer>
                  <Button
                    color="primary"
                    variant={'contained'}
                    onClick={() => handleShow()}
                    style={{ marginTop: '15px' }}
                    disabled={handleShowDisable}
                  >
                    Prikaži
                  </Button>
                </FlexContainer>
                {!show ? null : (
                  <Table
                    ok={ok}
                    nok={nok}
                    handleSetFilters={handleShow}
                    state={state}
                  />
                )}
              </FormWrapper>
            ) : (
              <Header>
                Unesite csv fajl
                <CgArrowTopRight />{' '}
              </Header>
            )}
          </>
        ) : (
          <TablePrice data={products} />
        )}
      </div>
    </>
  );
};

export default EOfferImport;
