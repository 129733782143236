import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { FiLogOut } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import messages from './messages';
import { logout } from 'containers/App/actions';

const Dropdown = ({ dropdown }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  return (
    <ul className="dropdown_menu" ref={dropdown}>
      <li>
        <Button className="button" onClick={() => dispatch(logout())}>
          <FiLogOut />
          {formatMessage(messages.logout)}
        </Button>
      </li>
    </ul>
  );
};

export default Dropdown;
