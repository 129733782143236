import React from 'react';
import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';

const Table = ({ data }) => {
  return (
    <TableHelper
      tableName="ourSuppliersProducts"
      tableHeaders={['Sku', 'Naziv', 'Kategorija', 'Cena', 'Preporučena cena']}
    >
      {data.map(item => (
        <TableRow key={item.id}>
          <TableCell>{item.sku}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.category}</TableCell>
          <TableCell>{item.price}</TableCell>
          <TableCell>{item.recommended_price}</TableCell>
        </TableRow>
      ))}
    </TableHelper>
  );
};

export default Table;
