import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  makeSelectIsAuthenticated,
  makeSelectUser
} from 'containers/App/selectors';
import { getItem } from 'utils/localStorage';

export function PrivateRoute({
  component: Component,
  isAuthenticated,
  module,
  user,
  role,
  ...rest
}) {
  const { goBack } = useHistory();
  const modules = getItem('modules') || [];

  const rendering = props => {
    if (isAuthenticated) {
      if (module && !modules.includes(module)) {
        return goBack();
      } else {
        if (user) {
          if (user.roles.includes('super-admin')) {
            return <Component {...props} />;
          } else {
            if (!role) return <Component {...props} />;
            else {
              if (user.permissions.includes(role)) {
                return <Component {...props} />;
              } else {
                return <></>;
              }
            }
          }
        }
      }
    } else {
      return <Redirect to="/login" />;
    }
  };
  return <Route {...rest} render={props => rendering(props)} />;
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
  user: makeSelectUser()
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PrivateRoute);
