import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from 'utils/request';
import { getItem, removeItem, setItem } from 'utils/localStorage';
import {
  fetchAuthenticatedUserSuccess,
  logoutSuccess,
  setModules
} from './actions';
import types from './constants';
import routes from 'routes';
import { makeSelectModules } from './selectors';

export function* fetchUser() {
  try {
    const { data } = yield call(request, {
      url: 'users/token?include=addresses',
      method: 'get'
    });
    yield put(fetchAuthenticatedUserSuccess(data));
  } catch (error) {}
}

export function* logout() {
  const selectModules = yield select(makeSelectModules());
  const modules = getItem('modules') || selectModules;
  yield call(removeItem, 'token');

  try {
    yield call(request, {
      url: 'logout',
      method: 'post'
    });
    yield put(logoutSuccess());
    if (modules.includes('Frontend')) {
      yield put(push(routes.HOME));
    }
  } catch (error) {}
}

export function* fetchModules() {
  try {
    const res = yield call(request, {
      url: 'modules',
      method: 'get'
    });
    let arr = [];
    Object.entries(res).map(item => arr.push(item[1]));
    yield call(setItem, 'modules', arr);
    yield put(setModules(arr));
  } catch (error) {}
}

export default function* appSaga() {
  yield takeLatest(types.FETCH_AUTHENTICATED_USER_REQUEST, fetchUser);
  yield takeLatest(types.LOGOUT_REQUEST, logout);
  yield takeLatest(types.GET_MODULES, fetchModules);
}
