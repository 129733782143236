import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'cms/components/Selects/redux/reducer';
import saga from 'cms/components/Selects/redux/saga';
import { fetchDiscountsTypes } from 'cms/components/Selects/redux/actions';
import { makeSelectDiscountsTypes } from 'cms/components/Selects/redux/selectors';

import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import messages from 'cms/components/Selects/messages';
import useUserKeyDown from 'helpers/useUserKeyDown';
import { searched } from 'helpers/search';

const key = 'selects';

const DiscountTypes = ({ params, setParams }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);
  const results = useSelector(makeSelectDiscountsTypes());
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isVisible && params && results.length) {
      let activeItem = results
        .map(item => item.name)
        .indexOf(params.discount_type);
      if (activeItem > -1) setActiveIndex(activeItem);
      else setActiveIndex(0);
    }
  }, [isVisible, results, params]);

  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useUserKeyDown('ArrowUp', [activeIndex, isVisible, data], () => {
    if (isVisible) {
      if (activeIndex > 0) setActiveIndex(prev => prev - 1);
      else setActiveIndex(data.length - 1);
    }
  });

  useUserKeyDown('ArrowDown', [activeIndex, isVisible, data], () => {
    if (isVisible) {
      if (activeIndex < data.length - 1) setActiveIndex(prev => prev + 1);
      else setActiveIndex(0);
    }
  });

  useUserKeyDown('Enter', [activeIndex, isVisible, data], () => {
    if (isVisible && data.length) handleClick(data[activeIndex]);
  });

  useEffect(() => {
    if (isVisible) dispatch(fetchDiscountsTypes());
  }, [isVisible, dispatch]);

  useEffect(() => {
    if (results.length) setData(results);
  }, [results]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    setData(searched(e, results, 'name'));
    setActiveIndex(0);
  };

  const handleClick = item => {
    setParams({ ...params, discount_type: item.name });
    toggle();
  };

  const handleDelete = () => {
    const { discount_type: tmp, ...rest } = params;
    setParams(rest);
  };

  return (
    <div className={activeClass('search_select_wrapper mt-20', isVisible)}>
      <div className="search_select_holder">
        <div className="root" onClick={toggle}>
          <p>
            {params.discount_type ? (
              <span>
                {params.discount_type}
                <FaTimes onClick={handleDelete} />
              </span>
            ) : (
              formatMessage(messages.choose_action_type)
            )}
          </p>
          <span className="icon">
            <FaSortDown />
          </span>
        </div>
        <label>{formatMessage(messages.action_type)}</label>
        {isVisible && (
          <div className="dropdown_holder" ref={modal}>
            <div className="dropdown_content">
              <input
                type="text"
                placeholder={formatMessage(messages.search_placeholder)}
                onChange={handleChange}
                autoFocus
              />
              {data.length ? (
                <ul>
                  {data.map((item, index) => (
                    <li
                      key={item.id}
                      onClick={() => handleClick(item)}
                      onMouseEnter={() => setActiveIndex(index)}
                      className={activeClass('', activeIndex === index)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no_result">{formatMessage(messages.no_result)}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscountTypes;
