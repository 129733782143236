import React from 'react';
import { useIntl } from 'react-intl';
import { FaCheck } from 'react-icons/fa';
import { activeClass } from 'helpers/activeClass';
import messages from '../messages';

const Photos = ({ params, setParams }) => {
  const { formatMessage } = useIntl();

  const toggle = name => {
    if (params[name]) {
      const { [name]: tmp, ...rest } = params;
      setParams(rest);
    } else {
      setParams({ ...params, [name]: 1 });
    }
  };

  return (
    <div className="group">
      <h3>{formatMessage(messages.photo_in_products)}</h3>
      <div className="form_checkbox" onClick={() => toggle('with_image')}>
        <div className={activeClass('box', params.with_image === 1)}>
          <span>
            <FaCheck />
          </span>
        </div>
        <label>{formatMessage(messages.with_photos)}</label>
      </div>
      <div className="form_checkbox" onClick={() => toggle('without_image')}>
        <div className={activeClass('box', params.without_image === 1)}>
          <span>
            <FaCheck />
          </span>
        </div>
        <label>{formatMessage(messages.without_photos)}</label>
      </div>
    </div>
  );
};

export default Photos;
