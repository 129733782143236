import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSuppliers = state => state._suppliers || initialState;

const makeSelectSuppliers = () =>
  createSelector(selectSuppliers, substate => substate.suppliers);

export {
  makeSelectSuppliers
};
