import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectNotifications = state => state.cms_notifications || initialState;

const makeSelectNotifications = () =>
  createSelector(selectNotifications, substate => substate.notifications);

const makeSelectUnreadNotifications = () =>
  createSelector(selectNotifications, substate => substate.unreadNotifications);

export { makeSelectNotifications, makeSelectUnreadNotifications };
