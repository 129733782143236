import React, { useState } from 'react';

import SideBar from 'cms/SideBar';
import NavBar from 'cms/NavBar';
import Routes from './Routes';

const Wrap = ({ user }) => {
  const [sideBarIsVisible, setSideBarIsVisible] = useState(false);

  return (
    <>
      <SideBar active={sideBarIsVisible} setActive={setSideBarIsVisible} />
      <NavBar
        user={user}
        statusSidebar={sideBarIsVisible}
        toggleSideBar={setSideBarIsVisible}
      />
      <main className="cms_content">
        <Routes />
      </main>
    </>
  );
};

export default Wrap;
