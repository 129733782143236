import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../messages';

const Range = ({ params, setParams, name, discount }) => {
  const { formatMessage } = useIntl();

  const handleChange = e => {
    let el = e.target;
    if (params[el.name] && !el.value.length) {
      const { [el.name]: tmp, ...rest } = params;
      setParams(rest);
    } else setParams({ ...params, [el.name]: el.value });
  };

  return (
    <div className="price_from_to">
      <div className="form_field">
        <input
          type="number"
          name={`${name}_from`}
          value={params[`${name}_from`] ? params[`${name}_from`] : ''}
          onChange={handleChange}
        />
        <label>
          {formatMessage(discount ? messages.price_from : messages.from)}
        </label>
      </div>
      <div className="form_field">
        <input
          type="number"
          name={`${name}_to`}
          value={params[`${name}_to`] ? params[`${name}_to`] : ''}
          onChange={handleChange}
        />
        <label>
          {formatMessage(discount ? messages.price_to : messages.to)}
        </label>
      </div>
    </div>
  );
};

export default Range;
