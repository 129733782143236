import React, { useState } from 'react';
import UpdateDescription from './UpdateDescription';
import Discounts from './Discounts';
import DangerModal from './DangerModal';
import UpdateAttributes from './Attributes/UpdateAttributes';
import messages from '../messages';
import PassProducts from './PassProducts';
import Sticker from './Sticker';
import ActionForm from './Action';

const MoreTools = ({
  ids,
  params,
  handleUpdate,
  clear,
  checkAll,
  openFilterMenu,
  setCategoriesSelectVisible,
  selectCategoryId,
  massUpdateDiscounts,
  setSticker,
  setAction
}) => {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [attributesModal, setAttributesModal] = useState(false);
  const [discountsModal, setDiscountsModal] = useState(false);
  const [passProductsModal, setPassProductsModal] = useState(false);
  const [stickerModal, setStickerModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);

  const setStickerValue = data => {
    setSticker(data);
    setStickerModal(false);
  };
  const setActionValue = data => {
    setAction(data);
    setActionModal(false);
  };
  return (
    <>
      {ids.length || checkAll ? (
        <UpdateDescription
          open={descriptionModal}
          close={() => setDescriptionModal(false)}
          params={params}
          handleUpdate={handleUpdate}
          ids={ids}
          clear={clear}
        />
      ) : (
        <DangerModal
          open={descriptionModal}
          close={() => setDescriptionModal(false)}
          msg={messages.mass_desc_validation}
        />
      )}
      <UpdateAttributes
        params={params}
        open={attributesModal}
        close={() => setAttributesModal(false)}
        openFilterMenu={openFilterMenu}
        setCategoriesSelectVisible={setCategoriesSelectVisible}
      />
      {ids.length || checkAll ? (
        <Discounts
          open={discountsModal}
          close={() => setDiscountsModal(false)}
          params={params}
          onSubmit={massUpdateDiscounts}
          ids={ids}
          clear={clear}
        />
      ) : (
        <DangerModal
          open={discountsModal}
          close={() => setDiscountsModal(false)}
          msg={messages.mass_desc_validation}
        />
      )}
      {ids.length || checkAll ? (
        <Sticker
          open={stickerModal}
          close={() => setStickerModal(false)}
          params={params}
          onSubmit={setStickerValue}
          ids={ids}
          clear={clear}
        />
      ) : (
        <DangerModal
          open={stickerModal}
          close={() => setStickerModal(false)}
          msg={messages.mass_desc_validation}
        />
      )}
      {ids.length || checkAll ? (
        <ActionForm
          open={actionModal}
          close={() => setActionModal(false)}
          params={params}
          onSubmit={setActionValue}
          ids={ids}
          clear={clear}
        />
      ) : (
        <DangerModal
          open={actionModal}
          close={() => setActionModal(false)}
          msg={messages.mass_desc_validation}
        />
      )}
      <PassProducts
        open={passProductsModal}
        close={() => setPassProductsModal(false)}
        params={params}
        ids={ids}
        checkAll={checkAll}
        openFilterMenu={openFilterMenu}
        setCategoriesSelectVisible={setCategoriesSelectVisible}
      />
    </>
  );
};

export default MoreTools;
