import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectProducts = state => state.products || initialState;

const makeSelectProducts = () =>
  createSelector(selectProducts, substate => substate.products);

const makeSelectProduct = () =>
  createSelector(selectProducts, substate => substate.product);

const makeSelectProductError = () =>
  createSelector(selectProducts, substate => substate.productError);

const makeSelectParams = () =>
  createSelector(selectProducts, substate => substate.params);

const makeSelectUpdateDiscountsIsPending = () =>
  createSelector(selectProducts, substate => substate.discountsIsPending);

const makeSelectCreateDiscountIsPending = () =>
  createSelector(selectProducts, substate => substate.createDiscountIsPending);

const makeSelectCreateProductIsPending = () =>
  createSelector(selectProducts, substate => substate.createProductIsPending);

const makeSelectUpdateProductIsPending = () =>
  createSelector(selectProducts, substate => substate.updateProductIsPending);

const makeSelectAttributes = () =>
  createSelector(selectProducts, substate => substate.attributes);

const makeSelectNewValueName = () =>
  createSelector(selectProducts, substate => substate.newValueName);

const makeSelectCategoryAttributes = () =>
  createSelector(selectProducts, substate => substate.categoryAttributes);

const makeSelectCategoryAttributesError = () =>
  createSelector(selectProducts, substate => substate.categoryAttributesError);

const makeSelectCreateBrandError = () =>
  createSelector(selectProducts, substate => substate.createBrandError);

const makeSelectPhotos = () =>
  createSelector(selectProducts, substate => substate.photos);

const makeSelectSticker = () =>
  createSelector(selectProducts, substate => substate.sticker);

  
const makeSelectCatalogSticker = () =>
createSelector(selectProducts, substate => substate.catalogSticker);


const makeSelectPassedProducts = () =>
  createSelector(selectProducts, substate => substate.passedProducts);

const makeSelectAttributeValues = () =>
  createSelector(selectProducts, substate => substate.attributeValues);

export {
  makeSelectProducts,
  makeSelectProduct,
  makeSelectProductError,
  makeSelectParams,
  makeSelectUpdateDiscountsIsPending,
  makeSelectCreateProductIsPending,
  makeSelectUpdateProductIsPending,
  makeSelectCreateDiscountIsPending,
  makeSelectAttributes,
  makeSelectNewValueName,
  makeSelectCategoryAttributes,
  makeSelectCategoryAttributesError,
  makeSelectCreateBrandError,
  makeSelectPhotos,
  makeSelectPassedProducts,
  makeSelectAttributeValues,
  makeSelectSticker,
  makeSelectCatalogSticker
};
