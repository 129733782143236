import React, { useState, useEffect } from 'react';
import Categories from './Categories';
import Suppliers from './Suppliers';
import Status from './Status';
import Attributes from './Attributes';
import Photos from './Photos';
import Linking from './Linking';
import Live from './Live';
import WebPrice from './WebPrice';
import EntryPrice from './EntryPrice';
import Discounts from './Discounts';
import Buttons from './Buttons';
import BrandsSelect from 'cms/Products/Filters/Brands';
import ManufacturersSelect from 'cms/Products/Filters/Manufacturers';

const FiltersSideBar = ({
  close,
  params,
  setParams,
  categoriesSelectVisible,
  setCategoriesSelectVisible,
  setSelectCategoryId
}) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    if (params) setValues(params);
  }, [params]);

  const handleSubmit = e => {
    e.preventDefault();
    setParams(values);
    close();
  };

  return (
    <div className="filters_slide_bar_wrapper" onClick={close}>
      <div
        className="filters_slide_bar_holder"
        onClick={e => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div className="holder">
            <div className="left">
              <Status params={values} setParams={setValues} />
              <Photos params={values} setParams={setValues} />
              <Attributes params={values} setParams={setValues} />
              <Linking params={values} setParams={setValues} />
              <Live params={values} setParams={setValues} />
            </div>
            <div className="right">
              <div className="dropdowns_row">
                <Categories
                  params={values}
                  setParams={setValues}
                  isVisible={categoriesSelectVisible}
                  setIsVisible={setCategoriesSelectVisible}
                  satValue={setSelectCategoryId}
                />
                <Suppliers params={values} setParams={setValues} />
              </div>
              <div className="dropdowns_row" style={{ marginTop: '25px' }}>
                <BrandsSelect params={values} setParams={setValues} />
                <ManufacturersSelect params={values} setParams={setValues} />
              </div>
              <div className="web_price_row">
                <WebPrice params={values} setParams={setValues} />
              </div>
              <div className="web_price_row">
                <EntryPrice params={values} setParams={setValues} />
              </div>
              <Discounts params={values} setParams={setValues} />
              <Buttons
                setValues={setValues}
                setParams={setParams}
                close={close}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FiltersSideBar;
