const KEY = '[CMS_NOTIFICATIONS]';

const types = {
  GET_NOTIFICATIONS: `${KEY} GET_NOTIFICATIONS`,
  SET_NOTIFICATIONS: `${KEY} SET_NOTIFICATIONS`,

  GET_UNREAD_NOTIFICATIONS: `${KEY} GET_UNREAD_NOTIFICATIONS`,
  SET_UNREAD_NOTIFICATIONS: `${KEY} SET_UNREAD_NOTIFICATIONS`,

  READ_NOTIFICATION: `${KEY} READ_NOTIFICATION`,
  READ_ALL_NOTIFICATIONS: `${KEY} READ_ALL_NOTIFICATIONS`,

  DELETE_NOTIFICATION: `${KEY} DELETE_NOTIFICATION`,
  DELETE_ALL_NOTIFICATIONS: `${KEY} DELETE_ALL_NOTIFICATIONS`
};

export default types;
