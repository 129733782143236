import React from 'react';
import { FaRegDotCircle, FaTimes } from 'react-icons/fa';

import Favicon from 'assets/svg/Favicon';
import Logo from 'assets/images/logo.png';
import Image from 'components/Image/Loadable';

const TopPart = ({ toggle }) => (
  <div className="top">
    <div onClick={() => (window.location.href = '/')} className="logo">
      <Image src={Logo} />
      <Favicon />
    </div>
    <FaRegDotCircle className="circle" onClick={toggle} />
    <FaTimes className="times" onClick={toggle} />
  </div>
);

export default TopPart;
