const routes = {
  CMS_USERS: `/korisnici`,
  CMS_ADD_USER: `/dodaj-korisnika`,
  CMS_EDIT_USER: `/korisnici/:user_id`,
  CMS_INVOICES: `/profakture`,
  CMS_INVOICE: `/profakture/:invoice_id`,
  CMS_INVOICES_DATA: `/podaci`,
  CMS_INVOICES_EMAILS: `/profakture-emailovi`,
  CMS_INVOICES_BANKS: `/profakture-banke`,
  CMS_PRODUCTS: `/proizvodi`,
  CMS_PRODUCTS_VISIBLE_ON_SITE: `/vidljivi-na-sajtu`,
  CMS_E_OFFER: `/e-ponuda-export`,
  CMS_E_OFFER_IMPORT: `/e-ponuda-import`,
  CMS_ADD_PRODUCT: `/dodaj-proizvod`,
  CMS_PRODUCT: `/proizvodi/:product_id`,
  CMS_PRODUCT_DISCOUNT: `/proizvodi/:product_id/akcije`,
  CMS_PRODUCT_ATTRIBUTES: `/proizvodi/:product_id/atribut`,
  CMS_PRODUCT_PHOTOS: `/proizvodi/:product_id/slike`,
  CMS_PRODUCT_STICKER: `/proizvodi/:product_id/stiker`,
  CMS_PRODUCT_CATALOG_STICKER: `/proizvodi/:product_id/katalog_stiker`,
  CMS_PRODUCTS_CREATE_ATTRIBUTE: `/proizvodi/dodaj-atribute/:products_category_attribute_id`,
  CMS_CATEGORIES: `/kategorije`,
  CMS_ADD_CATEGORY: `/dodaj-kategoriju`,
  CMS_CATEGORY: `/kategorije/:category_id`,
  CMS_WEB_MARGINS: `/web-marže`,
  CMS_ADD_WEB_MARGIN: `/dodaj-web-maržu`,
  CMS_EDIT_WEB_MARGIN: `/web-marže/:margin_id`,
  CMS_RECOMMENDED_PRICE: `/preporučene-cene`,
  CMS_ADD_RECOMMENDED_PRICE: `/dodaj-preporučenu-cenu`,
  CMS_EDIT_RECOMMENDED_PRICE: `/preporučene-cene/:recommended_price_id`,
  CMS_ATTRIBUTES: `/atributi`,
  CMS_BRANDS: `/brendovi`,
  CMS_UPLOAD_PRICELIST: `/ažuriraj-cenovnik`,
  CMS_OUR_SUPPLIERS: `/naši-dobavljači`,
  CMS_OUR_SUPPLIERS_PRODUCTS: `/naši-dobavljači/:id`,
  CMS_CATALOG_STICKERS: `/katalog-nalepnice`,
  CMS_LINKING_CATEGORIES: `/povezivanje-kategorija`,
  CMS_LINKING_PRODUCTS: `/povezivanje-proizvoda`,
  CMS_ACTIVITY_LOG: `/aktivnosti-korisnika`,
  CMS_AUTH_DISCOUNTS: `/autentifikovani-popust`,
  CMS_ADD_AUTH_DISCOUNTS: `/dodaj-autentifikovani-popust`,
  CMS_EDIT_AUTH_DISCOUNTS: `/autentifikovani-popust/:auth_discount_id`,
  CMS_DISCOUNTS_TYPES: `/tipovi-akcija`,
  CMS_ORDERS: `/porudžbine`,
  CMS_SHOW_ORDER: `/porudžbine/:order_id`,
  CMS_BANNERS: `/baneri`,
  CMS_ADD_BANNER: `/dodaj-baner`,
  CMS_EDIT_BANNER: `/baneri/:banner_id`,
  CMS_HOME_SCREEN_PRODUCTS: `/početna-strana-proizvodi`,
  CMS_HOME_SCREEN_CATEGORIES: `/početna-strana-kategorije`,
  CMS_HOME_SCREEN_SECTIONS: `/početna-strana-sekcije`,
  CMS_HOME_SCREEN_ADD_SECTION: `/početna-strana-dodaj-sekciju`,
  CMS_HOME_SCREEN_SECTION: `/početna-strana-sekcije/:section_id`,
  CMS_NEWS: `/vesti`,
  CMS_ADD_NEWS: `/dodaj-vest`,
  CMS_EDIT_NEWS: `/vesti/:news_id`,
  CMS_AUTHORIZATION: `/autorizacija`,
  CMS_NEWSLETTER: `/newsletter`,
  CMS_NOTIFICATIONS: `/notifikacije`,
  CMS_RATINGS: `/ocene`,
  CMS_RATING: `/ocene/:rating_id`,
  CMS_QUESTIONS_AND_ANSWERS: `/pitanja-i-odgovori`,
  CMS_SHOW_QUESTIONS_AND_ANSWERS: `/pitanja-i-odgovori/:questions_and_answers_id`,
  CMS_PRODUCTS_EXPORT: `/export-proizvoda`,
  CMS_PRODUCTS_EXPORT_ADD_CLIENT: `/export-proizvoda/dodaj-klijenta`,
  CMS_PRODUCTS_IMPORT_CATEGORY_TO_CATEGORY: `/import-proizvoda/kategorija-u-kategoriju`,
  CMS_PRODUCTS_IMPORT_PRODUCT_TO_CATEGORY: `/import-proizvoda/proizvod-u-kategoriju`,
  CMS_ACTIONS: `/akcije`
};

export default routes;
