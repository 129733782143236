import types from './constants';

export const getBanners = params => ({
  type: types.GET_BANNERS,
  params
});

export const setBanners = payload => ({
  type: types.SET_BANNERS,
  payload
});

export const changeOrder = payload => ({
  type: types.CHANGE_ORDER_REQUEST,
  payload
});


export const setParams = params => ({
  type: types.SET_PARAMS,
  params
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});

export const getBanner = banner_id => ({
  type: types.GET_BANNER,
  banner_id
});

export const setBanner = payload => ({
  type: types.SET_BANNER,
  payload
});

export const getBannerError = error => ({
  type: types.GET_BANNER_ERROR,
  error
});

export const getBannersPositions = () => ({
  type: types.GET_BANNERS_POSITIONS
});

export const setBannersPositions = payload => ({
  type: types.SET_BANNERS_POSITIONS,
  payload
});

export const createBanner = (payload, setErrors) => ({
  type: types.CREATE_BANNER,
  payload,
  meta: { setErrors }
});

export const updateBanner = (banner_id, payload, setErrors) => ({
  type: types.UPDATE_BANNER,
  banner_id,
  payload,
  meta: { setErrors }
});

export const updateBannerPhoto = (banner_id, photo) => ({
  type: types.UPDATE_BANNER_PHOTO,
  banner_id,
  photo
});

export const updateBannerMobilePhoto = (banner_id, photo) => ({
  type: types.UPDATE_BANNER_MOBILE_PHOTO,
  banner_id,
  photo
});

export const deleteBanner = (banner_id, params) => ({
  type: types.DELETE_BANNER,
  banner_id,
  params
});

export const setPosition = payload => ({
  type: types.SET_POSITION,
  payload
});
