import { activeLink } from 'helpers/activeClass';

export const classes = (pathname, route) => {
  if (
    pathname.includes('korisni') &&
    route.includes('korisni') &&
    !pathname.includes('aktivnosti') &&
    !route.includes('aktivnosti')
  )
    return 'active';
  if (
    pathname.includes('proizvodi') &&
    route.includes('proizvodi') &&
    !pathname.includes('povezivanje') &&
    !route.includes('povezivanje') &&
    !pathname.includes('početna-strana') &&
    !route.includes('početna-strana') &&
    !pathname.includes('export') &&
    !route.includes('export') &&
    !pathname.includes('import') &&
    !route.includes('import')
  )
    return 'active';
  if (
    pathname.includes('kategorij') &&
    route.includes('kategorij') &&
    !pathname.includes('povezivanje') &&
    !route.includes('povezivanje') &&
    !pathname.includes('početna-strana') &&
    !route.includes('početna-strana') &&
    !pathname.includes('import') &&
    !route.includes('import')
  )
    return 'active';
  if (pathname.includes('porudžbine') && route.includes('porudžbine'))
    return 'active';
  if (pathname.includes('vest') && route.includes('vest')) return 'active';
  if (pathname.includes('web-marž') && route.includes('web-marž'))
    return 'active';
  if (pathname.includes('baner') && route.includes('baner')) return 'active';
  if (pathname.includes('atributi') && route.includes('atributi'))
    return 'active';
  if (pathname.includes('preporuče') && route.includes('preporuče'))
    return 'active';
  if (pathname.includes('dobavljači') && route.includes('dobavljači'))
    return 'active';
  if (pathname.includes('autentifikovani') && route.includes('autentifikovani'))
    return 'active';
  if (
    pathname.includes('import-proizvoda') &&
    route.includes('import-proizvoda')
  )
    return 'active';
  return activeLink(pathname, route);
};
